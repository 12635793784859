import axios from "axios";
import React from "react";
import { useState,useEffect} from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Select,
  MenuItem
} from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import "dayjs/locale/es-mx";
import { styled } from "@mui/system";
import {
  GridColDef,
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingModal from "../widgets/LoadingModal";
import ErrorModal from "../widgets/ErrorModal";
import Papa from "papaparse"
import SuccessModal from "../widgets/SuccessModal";
import * as XLSX from 'xlsx';
import { ComposedChart, Area,LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Mexico_City");

var JSZip = require('jszip');
var zip = new JSZip



const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const formStyle = {
  padding: "1em",
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const columns = [
  { field: "usuario", headerName: "Usuario", width: 120 },
  { field: "fecha",   headerName: "Fecha", width: 120 },
  { field: "hora_1",  type:'number',  headerName: "Hora 1",  width: 85 },
  { field: "hora_2",  type:'number',  headerName: "Hora 2",  width: 85 },
  { field: "hora_3",  type:'number',  headerName: "Hora 3",  width: 85 },
  { field: "hora_4",  type:'number',  headerName: "Hora 4",  width: 85 },
  { field: "hora_5",  type:'number',  headerName: "Hora 5",  width: 85 },
  { field: "hora_6",  type:'number',  headerName: "Hora 6",  width: 85 },
  { field: "hora_7",  type:'number',  headerName: "Hora 7",  width: 85 },
  { field: "hora_8",  type:'number',  headerName: "Hora 8",  width: 85 },
  { field: "hora_9",  type:'number',  headerName: "Hora 9",  width: 85 },
  { field: "hora_10", type:'number',  headerName: "Hora 10", width: 85 },
  { field: "hora_11", type:'number',  headerName: "Hora 11", width: 85 },
  { field: "hora_12", type:'number',  headerName: "Hora 12", width: 85 },
  { field: "hora_13", type:'number',  headerName: "Hora 13", width: 85 },
  { field: "hora_14", type:'number',  headerName: "Hora 14", width: 85 },
  { field: "hora_15", type:'number',  headerName: "Hora 15", width: 85 },
  { field: "hora_16", type:'number',  headerName: "Hora 16", width: 85 },
  { field: "hora_17", type:'number',  headerName: "Hora 17", width: 85 },
  { field: "hora_18", type:'number',  headerName: "Hora 18", width: 85 },
  { field: "hora_19", type:'number',  headerName: "Hora 19", width: 85 },
  { field: "hora_20", type:'number',  headerName: "Hora 20", width: 85 },
  { field: "hora_21", type:'number',  headerName: "Hora 21", width: 85 },
  { field: "hora_22", type:'number',  headerName: "Hora 22", width: 85 },
  { field: "hora_23", type:'number',  headerName: "Hora 23", width: 85 },
  { field: "hora_24", type:'number',  headerName: "Hora 24", width: 85 },
  { field: "hora_25", type:'number',  headerName: "Hora 25", width: 85 },
];

export default function Nominacion_x_user({ auth }) {
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [messageData, setMessageData] = useState([])
  const handleClose = () => setError(false);
  const handleCloseS = () => setSuccess(false);
  const [file, setFile] = useState([]);
  const [values, setValues] = useState({
    dateI : dayjs(new Date()),
    "fecha-inicio":dayjs().subtract(6,"hours").format('YYYY-MM-DD'),
    carga: "sel"
  });
  const [data, setData] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [dataGeneralChart, setDataGeneralChart] = useState([]);
  const url = `${process.env.REACT_APP_URL_BACK}`;
  const [cargas,setCargas] = useState([])

  const [jose,setJose] = useState([])
  const [abra,setAbra] = useState([])
  const [carl,setCarl] = useState([])

  useEffect(() => {
     init()
     getData()
  }, []);

  async function init() {
    setLoad(true)
    const getResponse = await get("servicio/nominacion/get")
      if(getResponse["Status"] == "Ok"){
          setLoad(false)
          setCargas(getResponse["cargas"])
      }else{
        setLoad(false)
        setMessageData(getResponse["Data"])
        setError(true)
      }
  } 

  async function getData() {
    setLoad(true)
    const getResponse = await post("servicio/nominacion/data",{'Fecha':values['fecha-inicio'],'Carga':values['carga']})
      if(getResponse["Status"] == "Ok"){
          setLoad(false)
          setData(getResponse["total"])
          setDataChart(getResponse["data_graph"])
          setDataGeneralChart(getResponse["general_graph"])
      }else{
        setLoad(false)
        setMessageData(getResponse["Data"])
        setError(true)
      }
  } 

  const post = async(endpoint,info) =>{
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "token " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const response = await axios
    .post(url + endpoint, info, config)
    .then((response) => {
        return response.data
    })
    .catch((e) => {
      console.log(e)
      return e;
    });
    return response
  }

  const get = async(endpoint) =>{
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "token " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const response = await axios
    .get(url + endpoint, config)
    .then((response) => {
        return response.data
    })
    .catch((e) => {
      console.log(e)
      return e;
    });
    return response
  }

  function parse(buffer){
      try
      {
        const workbook = XLSX.read(buffer,{type:'binary'});
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(sheet)
        return [1,sheetData]
      }
      catch (error) 
      {
        return [-1,error]
      }
  }

  function parseCsv(){
    return new Promise((resolve, reject) => {
      Papa.parse(file,{
        complete: function(results){
            resolve(results.data)
        },
        error: function(error){
          reject(error)
        }
      })
    });
  }

  function readZip(){
    const reader = new FileReader
    return new Promise((resolve,reject) =>{
      try {
        var result = [{'Status':1}]
        zip.loadAsync(file)
        .then(function(zip){
          Object.keys(zip.files).forEach(function (filename) {
            zip.files[filename].async("ArrayBuffer").then(function (fileData) {
              if(filename.includes("nominacion ")){
                result.push({"Abra":fileData})
                parse(fileData)
              }
              else if(filename.includes("nominacion")){
                parse(fileData)
              }
              else if(filename.includes("tiresias")){
                result.push({"Jose":fileData})          
              }
            })
          })
        });

        resolve(result)
      } catch (error) {
        reject('Error al leer el archivo')
      }
    });
  }

  async function submit(event){
      event.preventDefault()
      setLoad(true)      
      var buffer = []
      var result = []
      zip.loadAsync(file)
      .then(function(zip){
        var formData = {
          "Fecha": values['fecha-inicio'],
          "Jose": {
            "OCCIDENTAL":{},
            "CENTRAL":{},
            "ARCELOR":{},
            "ORIENTAL":{},
            "NORESTE":{},
            "NOROESTE":{},
            "NORTE":{},
            "BAJA CALIFORNIA":{}
          },
          "Abraham":{},
          "Carlos":{}
        }
        var form_jose = undefined
        var form_carl = undefined
        var form_abra = undefined
        Object.keys(zip.files).forEach(function (filename) {
          if(filename.includes("nominacion ")){
            form_carl = zip.files[filename].async("ArrayBuffer").then(function (fileData){
              const d = parse(fileData)
              d[1].forEach((keys) => {
                for(const e in keys){
                  if(e != '__EMPTY')
                  if(formData["Carlos"][e.toUpperCase()] != undefined){
                    formData["Carlos"][e.toUpperCase()]["h_".concat(parseInt(keys['__EMPTY']) + 1)] = parseFloat(keys[e]).toFixed(1)    
                  }
                  else{
                    formData["Carlos"][e.toUpperCase()] = {}
                    formData["Carlos"][e.toUpperCase()]["h_".concat(parseInt(keys['__EMPTY']) + 1)] = parseFloat(keys[e]).toFixed(1) 
                  }
                }
              })
            })
          }
          else if(filename.includes("nominacion")){
            form_abra =  zip.files[filename].async("ArrayBuffer").then(function (fileData){
              const d = parse(fileData)
              d[1].forEach((keys) => {
                for(const e in keys){
                  if(e != '__EMPTY')
                  if(formData["Abraham"][e.toUpperCase()] != undefined){
                    formData["Abraham"][e.toUpperCase()]["h_".concat(parseInt(keys['__EMPTY']) + 1)] = parseFloat(keys[e]).toFixed(1)    
                  }
                  else{
                    formData["Abraham"][e.toUpperCase()] = {}
                    formData["Abraham"][e.toUpperCase()]["h_".concat(parseInt(keys['__EMPTY']) + 1)] = parseFloat(keys[e]).toFixed(1) 
                  }
                }
              })
            })
          }
          else if(filename.includes("tiresias")){
            form_jose = zip.files[filename].async("string").then(function (fileData){
              const d = fileData
              const rows = d.split("\n")
              rows.forEach((e)=> {
                const row = e.split(",")
                if(row[1] != 'hora' && row[1] != undefined){
                  formData["Jose"]["OCCIDENTAL"]["h_".concat(row[1])] = parseFloat(row[2]).toFixed(1)
                  formData["Jose"]["CENTRAL"]["h_".concat(row[1])] = parseFloat(row[6]).toFixed(1)
                  formData["Jose"]["ARCELOR"]["h_".concat(row[1])] = 0
                  formData["Jose"]["ORIENTAL"]["h_".concat(row[1])] = 0
                  formData["Jose"]["NORESTE"]["h_".concat(row[1])] = parseFloat(row[5]).toFixed(1)
                  formData["Jose"]["NOROESTE"]["h_".concat(row[1])] = parseFloat(row[3]).toFixed(1)
                  formData["Jose"]["NORTE"]["h_".concat(row[1])] = parseFloat(row[4]).toFixed(1)
                  formData["Jose"]["BAJA CALIFORNIA"]["h_".concat(row[1])] = 0
                }
              })
            })          
          }
        })

        Promise.all([form_abra,form_jose,form_carl]).then((values) => {
          async function upload(data) {
            const response  = await post("servicio/nominacion/get",data)
            if (response["Status"] == "Ok"){
                  setLoad(false)
                  setMessageData(response["Data"])
                  setSuccess(true)
                  getData()
            }
            else{
              setLoad(false)
              setMessageData(response["Data"])
              setError(true)
            }
          }
          upload(formData)
        })
      });
  }

  function onChangeCal(event, type) {
    let v = values;
    v[type] = dayjs(event.$d.toISOString()).format("YYYY-MM-DD");
    
    if(type == "fecha-inicio")
      v['dateI'] = dayjs(event.$d.toISOString())
    if(type == "fecha-fin")
      v['dateF'] = dayjs(event.$d.toISOString())
    setValues(v);
    getData()
   
  }

  function getCarga(event){
    let v = values
    v['carga'] = event.target.value
    setValues(v)
    getData()
  }

  function Form() {
    return (
      <form style={{ display: "flex", alignItems: "center" }} onSubmit={submit}>
        <FormControl style={formStyle}>
          <FormLabel>Fecha del archivo:</FormLabel>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="es-mx"
          >
            <DatePicker
              defaultValue={values.dateI}
              format="YYYY-MM-DD"
              onChange={(e) => onChangeCal(e, "fecha-inicio")}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl style={formStyle}>
          <FormLabel>Archivo ZIP: {file['name']}</FormLabel>
          <Button
            component= "label"
            role = {undefined}
            variant="outlined"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            sx={{ height: "50px" }}
          >
            Selecciona tu archivo
            <VisuallyHiddenInput type="file" onChange={(e) => {setFile(e.target.files[0]);} }/>
          </Button>
        </FormControl>

        <FormControl style={formStyle}>
          <br/>
          <Button
            color="primary"
            type="submit"
            variant="outlined"
            sx={{ height: "50px" }}
          >
            Subir archivo
          </Button>
        </FormControl>
        
      </form>
    );
  }

  function Table() {
    return (
      <div style={{ height: 375, width: "100%" }}>
        <DataGrid
          rows={data}
          columns={columns}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
    );
  }

  function Chart() {
    return (
      <div style={{ height: 500, width: "100%" }}>
        <ResponsiveContainer height={500}>
          <ComposedChart
          width={500}
          height={300}
          data={dataChart}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey={"hora"}/>
            <YAxis/>
            <Tooltip/>
            <Legend/>
            <Line type="monotone" dot={false} dataKey="Jose"    stroke="#0000FF"/>
            <Line type="monotone" dot={false} dataKey="Abraham" stroke="#FF0000"/>
            <Line type="monotone" dot={false} dataKey="Carlos"  stroke="#008000"/>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    )
  }

  function ChartGeneral() {
    return (
      <div style={{ height: 500, width: "100%" }}>
        <ResponsiveContainer height={500}>
          <ComposedChart
          width={500}
          height={300}
          data={dataGeneralChart}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey={"hora"}/>
            <YAxis/>
            <Tooltip/>
            <Legend/>
            <Line type="monotone" dot={false} dataKey="Jose"    stroke="#0000FF"/>
            <Line type="monotone" dot={false} dataKey="Abraham" stroke="#FF0000"/>
            <Line type="monotone" dot={false} dataKey="Carlos"  stroke="#008000"/>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    )
  }

  return (
    <>
      {auth ? (
        <>
          <Box
            sx={{
              margin: "10px",
              padding: "0 10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Link href="/">
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <h1>Nominación</h1>
            </Box>
            
            <Form/>
            
            <Table/>
            <h1>Suma de todas las cargas por hora y usuario.</h1>
            <ChartGeneral/>

            <LoadingModal isLoad={load} />
            <ErrorModal open={error} close={handleClose} data={messageData}/>
            <SuccessModal open={success} close={handleCloseS} data={messageData}/>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              margin: "10px",
              padding: "0 10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Link href="/">
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <h1>Inicia Sesión</h1>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
